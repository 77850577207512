<template>
    <auth-layout>
        <div class="container">
            <loading-indicator
                v-if="loading"
                :title="$t('global.loadingMessage.loading')"
            />
            <div
                v-else
                class="row"
            >
                <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <img
                        src="../assets/images/global/aven.svg"
                        width="71"
                        class="mb-5"
                        alt=""
                    >
                    <h5
                        class="mb-4"
                        v-html="$t('pages.passwordLogin.loginWithPassword')"
                    />
                    <form-container
                        ref="passwordForm"
                        class="mb-3"
                        @onSubmit="passwordLogin"
                        id="login-form"
                        data-testid="password-form"
                    >
                        <form-field
                            ref="passwordInput"
                            v-model="password"
                            name="password"
                            input-type="password"
                            class="mb-2"
                            validation="required"
                            validation-mode="lazy"
                            :placeholder="$t('pages.passwordLogin.placeHolder')"
                            data-testid="password-form-password-input"
                        />
                        <base-button
                            type="submit"
                            :submitting="submittingPassword"
                            data-testid="password-form-submit"
                        >
                            {{ $t('global.cta.continue') }}
                        </base-button>
                    </form-container>
                    <base-button
                        button-classes="btn btn-tertiary"
                        @click="forgotPassword"
                        data-testid="forgot-password-button"
                    >
                        {{ $t('global.cta.forgotPassword') }}
                    </base-button>
                </div>
            </div>
        </div>
    </auth-layout>
</template>

<script lang="js">
    import AuthLayout from "@/layouts/Auth"
    import {appSessionStorage} from "@/utils/storage";
    import BaseButton from "@/components/base/BaseButton";
    import FormContainer from "@/components/base/FormContainer";
    import LoadingIndicator from "@/components/LoadingIndicator";

    import {i18n} from "@/utils/i18n";
    import {mapActions, mapGetters} from "vuex";
    import biometricsPassCodeMixin, {
        AppBlockedError
    } from "@/utils/biometricsPassCodeMixin";
    import FormField from "@/components/base/FormField";
    import {RouteNames, RoutePaths} from "@/routes/router";

    export default {
        components: {
            FormField,
            AuthLayout,
            BaseButton,
            FormContainer,
            LoadingIndicator,
        },
        computed: {
            ...mapGetters(['accountIsClosed', 'isSingleWebView', 'isWebView', 'biometricsEnabled']),
        },
        mixins: [biometricsPassCodeMixin],
        data() {
            return {
                loading: false,
                password: "",
                submittingPassword: false,
                RouteNames,
                RoutePaths,
            };
        },
        mounted() {
            this.$nextTick(() => this.$refs.passwordInput.setFocus())
        },
        methods: {
            ...mapActions(['updateAccountOverview']),
            applyGeneralError(errorMessage) {
                this.$refs.passwordForm.applyGeneralError(errorMessage)
            },
            forgotPassword: function() {
                this.$router.push({
                    name: RouteNames.SET_PASSWORD
                })
            },
            passwordLogin: async function() {
                const isValid = await this.$refs.passwordForm.$refs.observer.validate()
                if (!isValid || this.password === '') {
                    return
                }
                try {
                    this.submittingPassword = true
                    await this.loginWithPassword(this.password)
                    await this.getAccountOverview()
                } catch (error) {
                    this.password = ''
                    if (error.response?.status === 401) {
                        if (error.response.data.error === 'INCORRECT_PASSCODE_ERROR') {
                            this.applyGeneralError(i18n.t("pages.passwordLogin.error.incorrectPassword"))
                        } else {
                            appSessionStorage.clear()
                            await this.$router.replace(RoutePaths.LOGIN)
                        }
                    } else if (error.response?.status === 404) {
                        this.applyGeneralError(i18n.t("pages.passwordLogin.error.accountNotFound"))
                    } else if (error instanceof AppBlockedError) {
                        await this.presentAppBlockedView(error.blockEvent)
                    } else {
                        this.applyGeneralError(i18n.t("global.errors.generic"))
                    }
                } finally {
                    this.submittingPassword = false
                }
            }
        }
    };
</script>
